import { useAuth0 } from '@auth0/auth0-react'
import LoginPage from 'login/LoginPage'
import { createContext, useEffect, useState } from 'react'
import { Bacenta, ChurchLevel, Role, StreamOptions } from 'types/global-types'
import { ContextProviderProps } from './context.types'

export interface UserInterface {
  id: string
  firstName: string
  lastName: string
  fullName: string
  stream_name: StreamOptions
  church: { church: StreamOptions; subChurch: ChurchLevel }
  email: string
  constituency: string
  roles: Role[]
  pictureUrl?: string
  leadsBacenta?: Bacenta[]
}

interface UserContextInterface {
  currentUser: UserInterface
  setCurrentUser: React.Dispatch<any>
}

const initialCurrentUser: UserInterface = {
  id: '',
  pictureUrl: '',
  firstName: '',
  lastName: '',
  fullName: '',
  stream_name: 'Campus',
  church: { church: 'Campus', subChurch: 'Bacenta' },
  email: '',
  constituency: '',
  roles: [],
}

export const UserContext = createContext<UserContextInterface>({
  currentUser: initialCurrentUser,
  setCurrentUser: (state: UserContextInterface) => {},
})

export const UserContextProvider = ({ children }: ContextProviderProps) => {
  const { user, isAuthenticated } = useAuth0()

  const [currentUser, setCurrentUser] =
    useState<UserInterface>(initialCurrentUser)

  useEffect(() => {
    if (isAuthenticated) {
      setCurrentUser({
        id: user?.sub ? user.sub.replace('auth0|', '') : '',
        pictureUrl: user?.picture,
        firstName: user?.given_name || '',
        lastName: user?.family_name || '',
        fullName: user?.given_name + ' ' + user?.family_name,
        church: { church: 'Campus', subChurch: 'Bacenta' },
        stream_name: 'Campus',
        email: user?.email || '',
        constituency: '',
        roles: user ? user['https://flcadmin.netlify.app/roles'] : [],
      })
    }
  }, [user, isAuthenticated])

  if (!isAuthenticated) {
    return <LoginPage />
  }

  return (
    <UserContext.Provider value={{ currentUser, setCurrentUser }}>
      {children}
    </UserContext.Provider>
  )
}
